import React, { useContext, useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { PaymentMethodContext } from "../../index"

const ApplePayEmbedded = props => {
  const {
    dbsyApplePayEmbedded,
    paymentRequestDetails,
    dbsyApplePayEmbeddedMerchantSession,
  } = props

  const { onSetDibsyInitiateSessionApplepay, onSetDibsyInitiatePayment } =
    useContext(PaymentMethodContext)

  useEffect(() => {
    const applePayScript = document.createElement("script")
    applePayScript.src = dbsyApplePayEmbedded.payJsUrl
    applePayScript.async = true

    applePayScript.onload = function handleScriptLoaded() {
      const applePayButton = document.querySelector("apple-pay-button")

      applePayButton.addEventListener("click", async e => {
        e.preventDefault()
        onApplePayButtonClicked()
      })

      function onApplePayButtonClicked() {
        if (!ApplePaySession) {
          return
        }

        const request = {
          countryCode: "QA",
          currencyCode: paymentRequestDetails.currency,
          merchantCapabilities: ["supports3DS"],
          supportedNetworks: ["visa", "masterCard"],
          total: {
            label: "Services",
            type: "final",
            amount: paymentRequestDetails.amount,
          },
        }

        const session = new ApplePaySession(3, request)

        // Open the payment sheet
        session.onvalidatemerchant = event => {
          // Fetch the merchant session to validate the payment request
          fetch("https://paluat.developerqa.info/api/dibsy/applepay/session", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dbsyApplePayEmbedded.merchantId),
          })
            .then(res => res.json()) // Parse response as JSON.
            .then(merchantSession => {
              console.log(merchantSession)
              session.completeMerchantValidation(merchantSession)
            })
            .catch(err => {
              console.error("Error fetching merchant session", err)
            })
        }

        // Process token and respond to customer
        session.onpaymentauthorized = async event => {
          var isSuccess

          var model = {
            ApplePayToken: event.payment.token,
            TrackId: dbsyApplePayEmbedded.trackId,
            PaymentMethod: "applepay",
          }

          console.log(event.payment.token)
          try {
            // const response = await fetch("https://paldev.developerqa.info/api/dibsy/pay", {
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            //   body: JSON.stringify(model),
            // });

            const response = await onSetDibsyInitiatePayment(model)

            isSuccess = {
              status: 0,
            }
          } catch (error) {
            isSuccess = {
              status: 1,
            }
          }

          session.completePayment(isSuccess)
        }

        session.oncancel = event => {
          alert("Payment has been cancelled")
        }

        session.begin()
      }
    }

    applePayScript.onerror = function handleScriptError() {
      console.log("error loading script")
    }
    document.body.appendChild(applePayScript)
    return () => {
      document.body.removeChild(applePayScript)
    }
  }, [])

  function isJsonString(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  return (
    <>
      <div className="">
        <form>
          <div
            style={{
              height: "45px",
              backgroundColor: "black",
              borderRadius: "0.30rem",
              padding: "5px",
              fontSize: "7px",
              fontStyle: "italic",
              verticalAlign: "middle",
              overflow: "hidden",
            }}
            className="btn-block inner mb-2 fw-medium"
          >
            <apple-pay-button
              buttonstyle="black"
              type="plain"
              locale="en"
            ></apple-pay-button>
          </div>
        </form>
      </div>
    </>
  )
}

ApplePayEmbedded.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(ApplePayEmbedded))
