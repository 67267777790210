import React, { useState, useEffect } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { connect } from "react-redux"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import * as Yup from "yup"
import AuthUser from "components/AuthUser/AuthUser"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Label,
  Row,
  Form,
  FormFeedback,
  Button,
  Input,
  CardTitle,
} from "reactstrap"
import {
  registerMerchant,
  updateMerchant,
  getMerchantDetail,
  getMerchantList,
} from "store/actions"

function index(props) {
  const {
    onRegisterMerchant,
    merchantCreated,
    loading,
    merchantUpdated,
    onUpdateMerchat,
    onGetMerchantDetail,
    merchantDetail,
    onGetMerchantList,
    merchantList,
  } = props
  const [parseValue, setParseValue] = useState("")
  const user = useState(AuthUser())
  const [checkJson, setCheckJson] = useState()
  const [merchantLists, setMerchantLists] = useState([])
  const [merchantDetails, setMerchantDetails] = useState()
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
  const initialValues = {
    jsonValue: "",
  }
  const validationSchema = Yup.object({
    jsonValue: Yup.string().required("Please enter a valid JSON fomrat."),
  })
  const onSubmit = () => {
    if (
      parseValue &&
      (JSON.parse(parseValue)._id === "" ||
        JSON.parse(parseValue)._id === null) &&
      checkJson
    ) {
      onRegisterMerchant(JSON.parse(parseValue))
    } else if (
      parseValue &&
      (JSON.parse(parseValue)._id !== "" ||
        JSON.parse(parseValue)._id !== null) &&
      checkJson
    ) {
      onUpdateMerchat(JSON.parse(parseValue))
    }
  }
  const validation = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    onGetMerchantList()
  }, [])
  useEffect(() => {
    if (merchantList) {
      setMerchantLists(merchantList)
    }
  }, [merchantList])
  useEffect(() => {}, [merchantLists])
  useEffect(() => {}, [validation.values])
  useEffect(() => {}, [loading])
  useEffect(() => {}, [merchantCreated])
  useEffect(() => {}, [merchantUpdated])
  useEffect(() => {}, [checkJson])
  useEffect(() => {}, [parseValue])
  useEffect(() => {
    // console.log(merchantDetail)
    if (merchantDetail && merchantDetail.merchantId !== user[0].merchantId) {
      setMerchantDetails(merchantDetail)
    }
  }, [merchantDetail])
  useEffect(() => {
    //console.log(merchantDetails)
    if (merchantDetails && merchantDetails.merchantId !== user[0].merchantId) {
      validation.setValues({
        jsonValue: JSON.stringify(merchantDetails),
      })
    }
  }, [merchantDetails])

  function handleParseString(value) {
    if (value) {
      setParseValue(JSON.parse(value))
    }
  }

  function handleClearInput() {
    validation.setValues({
      jsonValue: "",
    })
    setParseValue("")
    setMerchantDetails({})
  }
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 10000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: false,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 300,
  }
  function showToastSuccess(message) {
    toastr.success(`Successfuly ${message}`, "")
    sleep(1000)
    validation.resetForm()
    window.location.reload()
  }
  function showToastError(message) {
    toastr.error(`${message} , Something went wrong`, "")
  }
  function showToastInfo(message) {
    toastr.info(message)
  }
  function handleCheckJson(value) {
    try {
      let o = JSON.parse(value)
      if (typeof o === "object") {
        setCheckJson(true)
      } else {
        setCheckJson(false)
      }
    } catch {
      setCheckJson(false)
    }
  }

  function handleMerchantSelect(value) {
    //console.log(value)
    if (value) {
      onGetMerchantDetail(value)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid>
          {merchantCreated && showToastSuccess("Created")}
          {merchantCreated === false && showToastError("Not Created")}
          {merchantUpdated && showToastSuccess("Updated")}
          {merchantUpdated === false && showToastError("Not Updated")}
          <Breadcrumbs title="Merchant" breadcrumbItem="Merchant Json" />
          <Row>
            <Col lg="12">
              {loading ? (
                <Row className="spinner-custom mx-auto my-auto pb-3">
                  <LoadingSpinnerThreeDots />
                </Row>
              ) : (
                <Form onSubmit={validation.handleSubmit}>
                  <Row>
                    <Col md="6">
                      <Card>
                        <CardBody>
                          <Label>
                            Merchant: <b>{merchantDetails?.merchantName}</b>
                          </Label>

                          <Input
                            name="merchantDetails"
                            type="select"
                            onChange={e => {
                              handleMerchantSelect(e.target.value)
                              setParseValue("")
                            }}
                            // onBlur={validation.handleBlur}
                            value={merchantDetails?.merchantName}
                            // invalid={
                            //   validation.touched.travelAgencyName &&
                            //   validation.errors.travelAgencyName
                            //     ? true
                            //     : false
                            // }
                            className="my-3"
                          >
                            <option value="" defaultValue hidden>
                              Select
                            </option>
                            {merchantLists.map((merchant, index) => {
                              // validation.values.customerId = customer.customerID
                              return (
                                <option value={merchant.merchantId} key={index}>
                                  {merchant.merchantName}
                                </option>
                              )
                            })}
                          </Input>
                          <Input
                            className="text-size-json"
                            type="textarea"
                            rows="15"
                            name="jsonValue"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.jsonValue}
                            invalid={
                              validation.touched.jsonValue &&
                              validation.errors.jsonValue
                                ? true
                                : false
                            }
                          />
                          {validation.touched.jsonValue &&
                          validation.errors.jsonValue ? (
                            <FormFeedback status="invalid">
                              {validation.errors.jsonValue}
                            </FormFeedback>
                          ) : null}
                          {checkJson === false &&
                          !validation.errors.jsonValue ? (
                            <>
                              <small className="color-danger">
                                Please check your JSON string.
                              </small>
                              <br />
                            </>
                          ) : null}
                          {merchantUpdated === false &&
                          merchantCreated === false ? (
                            <>
                              <small className="color-danger">
                                Please check your JSON string.
                              </small>
                              <br />
                            </>
                          ) : null}
                          <Row className="mx-auto my-1">
                            <Col>
                              {" "}
                              <small>
                                Please <b>Parse</b> your JSON format before{" "}
                                <b>Saving</b>.
                              </small>
                            </Col>
                          </Row>
                          <Button
                            color="warning"
                            className="my-2 mx-auto"
                            onClick={() => {
                              if (
                                typeof validation.values.jsonValue !== "object"
                              ) {
                                handleParseString(
                                  JSON.stringify(validation.values.jsonValue)
                                )
                              } else {
                                handleParseString(validation.values.jsonValue)
                              }
                              showToastInfo("Parsed JSON Format")
                              setCheckJson()
                              handleCheckJson(validation.values.jsonValue)
                            }}
                          >
                            <i className="mdi mdi-code-json mx-1" />
                            Parse
                          </Button>
                          {parseValue ? (
                            <Button
                              color="info"
                              className=" ml-2 "
                              onClick={() => {
                                showToastInfo("JSON copied.")
                                navigator.clipboard.writeText(parseValue)
                              }}
                            >
                              <i className="mdi mdi-content-copy mx-1" />
                              Copy Value
                            </Button>
                          ) : null}
                          {parseValue ? (
                            <Button
                              color="danger"
                              className=" ml-2 "
                              onClick={() => {
                                handleClearInput()
                              }}
                            >
                              <i className="mdi mdi-content-copy mx-1" />
                              Clear Value
                            </Button>
                          ) : null}
                          {parseValue && checkJson === true ? (
                            <Button
                              color="success"
                              className=" ml-2 "
                              type="submit"
                            >
                              <i className="mdi mdi-plus mx-1" />
                              Save Merchant
                            </Button>
                          ) : null}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card>
                        <CardTitle className="mx-auto my-2">
                          Parsed Json
                        </CardTitle>
                        <CardBody className="text-size-json">
                          {parseValue
                            .split(/(?:^|,)((?:[^\",]|\"[^\"]*\")*)/)
                            .map((value, index) => {
                              return (
                                <Row>
                                  <Col>
                                    {value}
                                    {index !==
                                      parseValue.split(
                                        /(?:^|,)((?:[^\",]|\"[^\"]*\")*)/
                                      ).length -
                                        2 && value !== ""
                                      ? ","
                                      : ""}
                                  </Col>
                                </Row>
                              )
                            })}

                          <br />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.Merchants.loading,
    merchantCreated: state.Merchants.merchantCreated,
    merchantUpdated: state.Merchants.merchantUpdated,
    merchantDetail: state.Merchants.merchantDetail,
    merchantList: state.Merchants.merchantList,
  }
}

const mapDispatchToProps = dispatch => ({
  onRegisterMerchant: data => dispatch(registerMerchant(data)),
  onUpdateMerchat: data => dispatch(updateMerchant(data)),
  onGetMerchantDetail: id => dispatch(getMerchantDetail(id)),
  onGetMerchantList: () => dispatch(getMerchantList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
