/* MERCHANT DETAIL*/
export const GET_MERCHANT_DETAIL = "GET_MERCHANT_DETAIL"
export const GET_MERCHANT_DETAIL_SUCCESS = "GET_MERCHANT_DETAIL_SUCCESS"
export const GET_MERCHANT_DETAIL_FAIL = "GET_MERCHANT_DETAIL_FAIL"

export const GET_MERCHANT_LIST = "GET_MERCHANT_LIST"
export const GET_MERCHANT_LIST_SUCCESS = "GET_MERCHANT_LIST_SUCCESS"
export const GET_MERCHANT_LIST_FAIL = "GET_MERCHANT_LIST_FAIL"

export const GET_MERCHANT_LIST_REPORT = "GET_MERCHANT_LIST_REPORT"
export const GET_MERCHANT_LIST_REPORT_SUCCESS =
  "GET_MERCHANT_LIST_REPORT_SUCCESS"
export const GET_MERCHANT_LIST_REPORT_FAIL = "GET_MERCHANT_LIST_REPORT_FAIL"

export const GET_MERCHANT_IDS = "GET_MERCHANT_IDS"
export const GET_MERCHANT_IDS_SUCCESS = "GET_MERCHANT_IDS_SUCCESS"
export const GET_MERCHANT_IDS_FAIL = "GET_MERCHANT_IDS_FAIL"

export const SET_MERCHANT = "SET_MERCHANT"
export const SET_MERCHANT_SUCCESS = "SET_MERCHANT_SUCCESS"
export const SET_MERCHANT_FAIL = "SET_MERCHANT_FAIL"

export const REGISTER_MERCHANT = "REGISTER_MERCHANT"
export const REGISTER_MERCHANT_SUCCESS = "REGISTER_MERCHANT_SUCCESS"
export const REGISTER_MERCHANT_FAIL = "REGISTER_MERCHANT_FAIL"

export const GET_MERCHANT_DETAIL_EXTERNAL = "GET_MERCHANT_DETAIL_EXTERNAL"
export const GET_MERCHANT_DETAIL_EXTERNAL_SUCCESS =
  "GET_MERCHANT_DETAIL_EXTERNAL_SUCCESS"
export const GET_MERCHANT_DETAIL_EXTERNAL_FAIL =
  "GET_MERCHANT_DETAIL_EXTERNAL_FAIL"

export const GET_MERCHANT_BY_OID = "GET_MERCHANT_BY_OID"
export const GET_MERCHANT_BY_OID_SUCCESS = "GET_MERCHANT_BY_OID_SUCCESS"
export const GET_MERCHANT_BY_OID_FAIL = "GET_MERCHANT_BY_OID_FAIL"

export const UPDATE_MERCHANT = "UPDATE_MERCHANT"
export const UPDATE_MERCHANT_SUCCESS = "UPDATE_MERCHANT_SUCCESS"
export const UPDATE_MERCHANT_FAIL = "UPDATE_MERCHANT_FAIL"

export const RETURN_STATE = "RETURN_STATE"
