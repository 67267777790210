import React from "react"
import { connect } from "react-redux"
import { Col, FormGroup, Row, Button, Form } from "reactstrap"

//TODO: SAVE ALONG WITH CUSTOM CONFIG , UPDATE MODEL (BSON IGNORE)
export const CustomConfig = props => {
  const {
    config,
    handleCustomConfigChange,
    handleCustomConfigDelete,
    handleSubmit,
    onSubmit,
    register,
    errors,
    index,
    indexPG,
  } = props

  const inputKey = "key" + indexPG + index
  const inputValue = "value" + indexPG + index

  function handleChange(changes) {
    handleCustomConfigChange(config.id, { ...config, ...changes })
  }

  return (
    <div
      style={{
        backgroundColor: "#b7c6e7",
        marginLeft: "20px",
        padding: "10px",
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="4">
            <FormGroup>
              <input
                value={config.key}
                name={inputKey}
                onChange={e => handleChange({ key: e.target.value })}
                type="text"
                className="form-control"
                placeholder="Enter Key"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors[inputKey] && errors[inputKey].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <input
                value={config.value}
                name={inputValue}
                onChange={e => handleChange({ value: e.target.value })}
                type="text"
                className="form-control"
                placeholder="Enter Value"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors[inputValue] && errors[inputValue].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Button
                onClick={e => handleCustomConfigDelete(config.id)}
                color="secondary"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                {" "}
                Delete Config
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CustomConfig)
