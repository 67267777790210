import React, { useEffect, useState, useContext } from "react"
import { Col, Form, FormGroup, Input, Label, Row, Button } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
// import rn from 'random-number'
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import { MerchantCreateContext } from "../index"

export const FirstMerchantInfo = props => {
  const {
    userAdmin,
    handleUserAdminChange,
    loadSubmitNowButton,
    toggleTabProgress,
    activeTabProgress,
  } = props

  const { register, handleSubmit, errors, reset, watch } = useForm()
  const { settingFourthStepError } = useContext(MerchantCreateContext)
  var options = {
    min: 100,
    max: 999,
    integer: true,
  }

  //const randomUserNumber = rn(options)

  // useEffect(() => {
  //     settingFourthStepError(errors)
  //    }, [errors])

  function handleChange(changes) {
    handleUserAdminChange({ ...userAdmin, ...changes })
  }

  const onSubmit = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
    }
  }

  function parseBool(value) {
    var bool = value === "true" ? true : false
    return bool
  }
  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <b>Add An Admin User</b>
        <p></p>

        <Row>
          <Col lg="2">
            <FormGroup>
              <Label>Title</Label>
              <select
                className="custom-select"
                value={userAdmin.title}
                name="title"
                onChange={e => handleChange({ title: e.target.value })}
                ref={register({ required: true })}
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
              </select>
              {errors.title && errors.title.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
          <Col lg="5">
            <FormGroup>
              <Label for="basicpill-firstname-input14">First Name</Label>
              <input
                value={userAdmin.firstName}
                name="firstName"
                onChange={e => handleChange({ firstName: e.target.value })}
                type="text"
                className="form-control"
                id="basicpill-firstname-input14"
                ref={register({ required: true })}
              />
              {errors.firstName && errors.firstName.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
          <Col lg="5">
            <FormGroup>
              <Label for="basicpill-lastname-input14">Last Name</Label>
              <input
                value={userAdmin.lastName}
                name="lastName"
                onChange={e => handleChange({ lastName: e.target.value })}
                type="text"
                className="form-control"
                id="basicpill-lastname-input14"
                ref={register({ required: true })}
              />
              {errors.lastName && errors.lastName.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="5">
            <FormGroup>
              <Label for="basicpill-email-input14">Email</Label>
              <input
                value={userAdmin.email}
                name="email"
                onChange={e => handleChange({ email: e.target.value })}
                type="text"
                className="form-control"
                id="basicpill-email-input14"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
              />

              <small className="color-danger">
                {errors.email && errors.email.message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label for="basicpill-mobile-input14">Mobile</Label>
              <input
                value={userAdmin.mobile}
                name="mobile"
                onChange={e => handleChange({ mobile: e.target.value })}
                type="text"
                className="form-control"
                id="basicpill-mobile-input14"
                ref={register({ required: true })}
              />
              {errors.mobile && errors.mobile.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Label>Department</Label>
              <select
                className="custom-select"
                value={userAdmin.department}
                name="department"
                onChange={e => handleChange({ department: e.target.value })}
                ref={register({ required: true })}
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                <option value="Administration">Administration</option>
                <option value="Finance">Finance</option>
                <option value="Operations">Operations</option>
                <option value="Sales and Marketing">Sales & Marketing</option>
              </select>
              {errors.department && errors.department.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Role</Label>
              <select
                className="custom-select"
                value={userAdmin.role}
                name="role"
                onChange={e => handleChange({ role: e.target.value })}
                ref={register({ required: true })}
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                <option value="Admin">Admin</option>
              </select>
              {errors.role && errors.role.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Status</Label>
              <select
                className="custom-select"
                value={userAdmin.isActive}
                name="isActive"
                onChange={e =>
                  handleChange({ isActive: parseBool(e.target.value) })
                }
                ref={register({ required: true })}
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              {errors.isActive && errors.isActive.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
        </Row>

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          >
            <Button
              color="primary"
              onClick={() => {
                toggleTabProgress(activeTabProgress - 1)
              }}
            >
              {" "}
              Previous{" "}
            </Button>
          </li>
          <li className={activeTabProgress === 5 ? "next" : "next"}>
            {activeTabProgress === 5 ? (
              <>{loadSubmitNowButton()}</>
            ) : (
              <Button
                type="submit"
                color="primary"
                id="my-form_submit_4"
                // onClick={() => { handleCheckNoErrors() }}
              >
                {" "}
                Next{" "}
              </Button>
            )}
          </li>
        </ul>
      </Form>
    </div>
  )
}

export default FirstMerchantInfo
