import React, { useState, useEffect } from "react"

import { CardTitle, Row, Col, Table } from "reactstrap"
function SellcoItems(props) {
  const { remarks } = props
  const [items, setItmes] = useState([])
  useEffect(() => {
    // console.log(remarks)
    let arr = []
    remarks?.map((val, i) => {
      if (
        val &&
        !arr.includes(val.REMARK_TEXT.replace(" ", "").replace(/ /g, "")) &&
        val.REMARK_TEXT.includes("*:I")
      ) {
        arr.push(val.REMARK_TEXT.replace(" ", "").replace(/ /g, ""))
      }
    })

    setItmes(arr)
  }, [remarks])
  useEffect(() => {}, [items])
  return (
    <React.Fragment>
      {items !== undefined && items.length > 0 ? (
        <Row>
          <Col sm="12">
            <CardTitle style={{ background: "#EAEFF5", padding: "1px" }}>
              {"ITEM DETAILS"}
            </CardTitle>
            <Table size="sm" style={{ textAlign: "left" }}>
              <tbody>
                <tr>
                  <td>
                    <small>
                      {" "}
                      <b>{"ITEM NAME"}</b>
                    </small>
                  </td>
                  <td>
                    <small>
                      {" "}
                      <b>{"QUANTITY"}</b>
                    </small>
                  </td>
                  <td>
                    <small>
                      {" "}
                      <b>{"AMOUNT"}</b>
                    </small>
                  </td>
                </tr>

                {items !== undefined &&
                  items?.map((item, index) => {
                    let i = item.split(":")
                    return (
                      <tr key={index}>
                        <td>
                          {" "}
                          <small>{i[2]}</small>
                        </td>
                        <td>
                          {" "}
                          <small>{i[3]}</small>
                        </td>
                        <td>
                          {" "}
                          <small>{i[4]}</small>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
  )
}

export default SellcoItems
