import React, {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react"
import { Col, Form, FormGroup, Label, Row, Button } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Contacts from "../Contacts"
import { v4 as uuidv4 } from "uuid"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import { MerchantCreateContext } from "../index"

export const FirstMerchantInfo = forwardRef((props, ref) => {
  const {
    handleMerchantChange,
    merchantDetails,
    loadSubmitNowButton,
    toggleTabProgress,
    activeTabProgress,
  } = props

  const { register, handleSubmit, errors, reset, watch } = useForm()
  const [pick_date, setPickDate] = useState("")
  const { settingFirstStepError } = useContext(MerchantCreateContext)

  // useEffect(() => {
  //     settingFirstStepError(errors)
  // }, [errors])

  function handleChange(changes) {
    handleMerchantChange({ ...merchantDetails, ...changes })
  }

  function handleContactChange(id, contact) {
    const newContacts = [...merchantDetails.contacts]
    const index = newContacts.findIndex(i => i.id === id)
    newContacts[index] = contact
    handleChange({ contacts: newContacts })
  }

  function handleContactAdd() {
    const newContact = {
      id: uuidv4(),
      name: "",
      mobile: "",
      email: "",
      position: "",
    }
    handleChange({ contacts: [...merchantDetails.contacts, newContact] })
  }

  function handleContactDelete(id) {
    handleChange({
      contacts: merchantDetails.contacts.filter(i => i.id !== id),
    })
  }

  //used to call this method from parent (not working and temporarily disabled)
  useImperativeHandle(ref, () => ({
    trigger() {
      settingFirstStepError(errors)
      document.getElementById("my-form_submit_1").click()
    },
  }))

  const onSubmit = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
    }
  }

  function parseBool(value) {
    var bool = value === "true" ? true : false
    return bool
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} id="my-form">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-merchantname-input14">Merchant Name</Label>
              <input
                type="text"
                value={merchantDetails.merchantName}
                name="merchantName"
                onChange={e => handleChange({ merchantName: e.target.value })}
                className="form-control"
                id="basicpill-merchantname-input14"
                ref={register({ required: true })}
              />
              {errors.merchantName && errors.merchantName.type === "required" && (
                <>
                  <small className="color-danger">This is required</small>
                </>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Merchant Type</Label>
              <select
                ref={register({ required: true })}
                className="custom-select"
                name="type"
                value={merchantDetails.type}
                onChange={e => handleChange({ type: e.target.value })}
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                <option value="Travel Agency">Travel Agency</option>
                <option value="Online Retail">Online Retail</option>
                <option value="Others">Others</option>
              </select>
              {errors.type && errors.type.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Status</Label>
              <select
                ref={register({ required: true })}
                className="custom-select"
                value={merchantDetails.isActive}
                name="isActive"
                onChange={e =>
                  handleChange({ isActive: parseBool(e.target.value) })
                }
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              {errors.isActive && errors.isActive.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-website-input15">Website</Label>
              <input
                value={merchantDetails.website}
                type="text"
                name="website"
                onChange={e => handleChange({ website: e.target.value })}
                className="form-control"
                id="basicpill-website-input15"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  pattern: {
                    value: /^https?:\/\/.+$/,
                    message: "invalid url",
                  },
                })}
              />
              <small className="color-danger">
                {errors.website && errors.website.message}
              </small>

              {/* {errors.Website && errors.Website.type === "required" && <small className="color-danger">This is required</small>} */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-tc-input15">Terms & Condition</Label>
              <input
                value={merchantDetails.termsConditionUrl}
                type="text"
                name="termsConditionUrl"
                onChange={e =>
                  handleChange({ termsConditionUrl: e.target.value })
                }
                className="form-control"
                id="basicpill-tc-input15"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  pattern: {
                    value: /^https?:\/\/.+$/,
                    message: "invalid url",
                  },
                })}
              />
              <small className="color-danger">
                {errors.termsConditionUrl && errors.termsConditionUrl.message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-phoneno-input16">Telephone</Label>
              <input
                value={merchantDetails.phoneNumber}
                type="text"
                name="PhoneNumber"
                onChange={e => handleChange({ phoneNumber: e.target.value })}
                className="form-control"
                id="basicpill-phoneno-input16"
                ref={register({ required: true })}
              />
              {errors.phoneNumber && errors.phoneNumber.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-bus-input17">
                Business Registration No
              </Label>
              <input
                value={merchantDetails.businessRegNumber}
                type="text"
                name="businessRegNumber"
                onChange={e =>
                  handleChange({ businessRegNumber: e.target.value })
                }
                className="form-control"
                id="basicpill-bus-input17"
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-tax-input16">Tax Registration No</Label>
              <input
                value={merchantDetails.taxRegNumber}
                type="text"
                name="taxRegNumber"
                onChange={e => handleChange({ taxRegNumber: e.target.value })}
                className="form-control"
                id="basicpill-tax-input16"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="4">
            <FormGroup>
              <Label for="basicpill-city-input16">City</Label>
              <input
                value={merchantDetails.city}
                type="text"
                name="city"
                onChange={e => handleChange({ city: e.target.value })}
                className="form-control"
                id="basicpill-city-input16"
                ref={register({ required: true })}
              />
              {errors.city && errors.city.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label>Country</Label>
              <select
                ref={register({ required: true })}
                className="custom-select"
                name="country"
                value={merchantDetails.country}
                onChange={e => handleChange({ country: e.target.value })}
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                <option value="Qatar">Qatar</option>
              </select>
              {errors.country && errors.country.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label for="basicpill-PoBox-input16">Po Box</Label>
              <input
                value={merchantDetails.poBox}
                type="text"
                name="poBox"
                onChange={e => handleChange({ poBox: e.target.value })}
                className="form-control"
                id="basicpill-PoBox-input16"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label for="basicpill-address-input2">Address</Label>
              <textarea
                value={merchantDetails.address}
                name="Address"
                onChange={e => handleChange({ address: e.target.value })}
                id="basicpill-address-input2"
                className="form-control"
                rows="2"
                ref={register({ required: true })}
              />
              {errors.address && errors.address.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
        </Row>

        <hr></hr>
        <b>Add Company Contacts</b>
        <p>For internal purposes only.</p>

        {merchantDetails.contacts &&
          merchantDetails.contacts.map((contact, index) => (
            <Contacts
              index={index}
              key={contact.id}
              contact={contact}
              handleContactChange={handleContactChange}
              handleContactDelete={handleContactDelete}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              errors={errors}
            />
          ))}

        <Button
          onClick={() => handleContactAdd()}
          color="success"
          className="mt-1"
        >
          Add New Contact
        </Button>

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          >
            <Button
              color="primary"
              onClick={() => {
                toggleTabProgress(activeTabProgress - 1)
              }}
            >
              {" "}
              Previous{" "}
            </Button>
          </li>
          <li className={activeTabProgress === 5 ? "next" : "next"}>
            {activeTabProgress === 5 ? (
              <>{loadSubmitNowButton()}</>
            ) : (
              <Button type="submit" color="primary" id="my-form_submit_1">
                {" "}
                Next{" "}
              </Button>
            )}
          </li>
        </ul>

        {/* <button style={{}} type='submit' hidden ref={ (button) => { this.activityFormButton = button } } >Submit</button> */}
        {/* <Button  positive form='my-form' content='Submit' value='Submit'  type="submit"/> */}
      </Form>
    </>
  )
})

export default FirstMerchantInfo
