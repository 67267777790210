import { call, put, takeEvery, takeLatest, take } from "redux-saga/effects"
import {
  getUserListByMerchantId,
  setUser,
  deleteUser,
  deactivateUser,
  updateNewUserPass,
  updateUserPass,
  updateUser,
  getUserDetail,
  getUserList,
  getAdminUserByMerchantId,
  isUserExisting,
  setDummyUser,
} from "helpers/fakebackend_helper"

import {
  GET_USER_LIST,
  GET_USER_LIST_BY_MERCHANTID,
  SET_USER,
  DELETE_USER,
  SET_NEW_USER_PASSWORD,
  UPDATE_USER,
  UPDATE_PASSWORD,
  GET_USER,
  DEACTIVATE_USER,
  GET_ADMIN_USER_BY_MERCHANTID,
  IS_USER_EXISTING,
  SET_DUMMY_USER,
} from "./actionTypes"

import {
  getUserListByMerchantIdSuccess,
  getUserListByMerchantIdFail,
  getUserListSuccess,
  getUserListFail,
  setUserSuccess,
  setUserFail,
  deleteUserSuccess,
  deleteUserFail,
  deactivateUserSuccess,
  deactivateUserFail,
  updateNewUserPassSuccess,
  updateNewUserPassFail,
  updateUserSuccess,
  updateUserFail,
  updateUserPassSuccess,
  updateUserPassFail,
  returnState,
  getUserDetailSuccess,
  getUserDetailFail,
  getAdminUserByMerchantIdSuccess,
  getAdminUserByMerchantIdFail,
  isUserExistingFail,
  isUserExistingSuccess,
  setDummyUserSuccess,
  setDummyUserFail,
} from "./action"

function* _getUserList() {
  try {
    const response = yield call(getUserList)

    if (response) {
      if (response?.isSuccess) yield put(getUserListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getUserListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getUserListFail(response.message))
      }
    } else yield put(getUserListFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getUserListFail(error?.message))
  }
}

function* _setUser({ payload: user }) {
  try {
    const response = yield call(setUser, user)

    if (response) {
      if (response?.isSuccess) yield put(setUserSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setUserFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setUserFail(response.message))
      }
    } else yield put(setUserFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(setUserFail(error?.message))
  }
}

function* _setDummyUser({ payload: user }) {
  try {
    const response = yield call(setDummyUser, user)

    if (response) {
      if (response?.isSuccess) yield put(setDummyUserSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setDummyUserFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setDummyUserFail(response.message))
      }
    } else yield put(setDummyUserFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(setDummyUserFail(error?.message))
  }
}

function* _getAdminUserByMerchantId({ payload: id }) {
  try {
    const response = yield call(getAdminUserByMerchantId, id)

    if (response) {
      if (response?.isSuccess)
        yield put(getAdminUserByMerchantIdSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getAdminUserByMerchantIdFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getAdminUserByMerchantIdFail(response.message))
      }
    } else
      yield put(
        getAdminUserByMerchantIdFail({ message: id + " Invalid Response." })
      )
  } catch (error) {
    yield put(setUsergetAdminUserByMerchantIdFailFail(error?.message))
  }
}

function* _deleteUser({ payload: user }) {
  // try {
  //   const response = yield call(deleteUser, user)
  //   if (response === true) {
  //     yield put(deleteUserSuccess(response))
  //   } else {
  //     yield put(deleteUserFail(response))
  //   }
  //   yield put(returnState())
  // } catch (error) {
  //   yield put(deleteUserFail(error))
  // }
  try {
    const response = yield call(deleteUser, user)

    if (response) {
      if (response?.isSuccess) yield put(deleteUserSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              deleteUserFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(deleteUserFail(response.message))
      }
    } else
      yield put(deleteUserFail({ message: user.Email + " Invalid Response." }))
  } catch (error) {
    yield put(deleteUserFail(error?.message))
  }
}

function* _deactivateUser({ payload: user }) {
  // try {
  //   const response = yield call(deactivateUser, user)
  //   if (response) {
  //     yield put(deactivateUserSuccess(response))
  //   } else {
  //     yield put(deactivateUserFail(response))
  //   }
  //   yield put(returnState())
  // } catch (error) {
  //   yield put(deactivateUserFail(error))
  // }
  try {
    const response = yield call(deactivateUser, user)

    if (response) {
      if (response?.isSuccess) yield put(deactivateUserSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              deactivateUserFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(deactivateUserFail(response.message))
      }
    } else
      yield put(deactivateUserFail({ message: user + " Invalid Response." }))
  } catch (error) {
    yield put(deactivateUserFail(error?.message))
  }
}

function* _updateNewUserPass({ payload: user }) {
  // try {
  //   const response = yield call(updateNewUserPass, user)
  //   if (response === true) {
  //     yield put(updateNewUserPassSuccess(response))
  //   } else {
  //     yield put(updateNewUserPassFail(false))
  //   }
  //   yield put(returnState())
  // } catch (error) {
  //   yield put(updateNewUserPassFail(error))
  // }
  try {
    const response = yield call(updateNewUserPass, user)

    if (response) {
      if (response?.isSuccess)
        yield put(updateNewUserPassSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updateNewUserPassFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(updateNewUserPassFail(response.message))
      }
    } else
      yield put(
        updateNewUserPassFail({ message: user.Email + " Invalid Response." })
      )
  } catch (error) {
    yield put(updateNewUserPassFail(error?.message))
  }
  yield put(returnState())
}

function* _updateUser({ payload: user }) {
  // try {
  //   const response = yield call(updateUser, user)
  //   if (response) {
  //     yield put(updateUserSuccess(response))
  //   } else {
  //     yield put(updateUserFail(response))
  //   }
  //   yield put(returnState())
  // } catch (error) {
  //   yield put(editUserFail(error))
  // }
  try {
    const response = yield call(updateUser, user)

    if (response) {
      if (response?.isSuccess) yield put(updateUserSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updateUserFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(updateUserFail(response.message))
      }
    } else
      yield put(updateUserFail({ message: user.UserId + " Invalid Response." }))
  } catch (error) {
    yield put(updateUserFail(error?.message))
  }
  yield put(returnState())
}

function* _updateUserPass({ payload: user }) {
  // try {
  //   const response = yield call(updateUserPass, user)
  //   if (response === true) {
  //     yield put(updateUserPassSuccess(response))
  //   } else {
  //     yield put(updateUserPassFail(response.message))
  //   }
  //   yield put(returnState())
  // } catch (error) {
  //   yield put(updateUserPassFail(error))
  // }
  try {
    const response = yield call(updateUserPass, user)

    if (response) {
      if (response?.isSuccess) yield put(updateUserPassSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updateUserPassFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(updateUserPassFail(response.message))
      }
    } else
      yield put(
        updateUserPassFail({ message: user.username + " Invalid Response." })
      )
  } catch (error) {
    yield put(updateUserPassFail(error?.message))
  }
  yield put(returnState())
}
function* _getUserDetail({ payload: user }) {
  // try {
  //   const response = yield call(getUserDetail, user)
  //   if (response) {
  //     yield put(getUserDetailSuccess(response))
  //   } else {
  //     yield put(getUserDetailFail(response))
  //   }
  // } catch (error) {
  //   yield put(getUserDetailFail(error))
  // }
  try {
    const response = yield call(getUserDetail, user)

    if (response) {
      if (response?.isSuccess) yield put(getUserDetailSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getUserDetailFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getUserDetailFail(response.message))
      }
    } else
      yield put(getUserDetailFail({ message: user + " Invalid Response." }))
  } catch (error) {
    yield put(getUserDetailFail(error?.message))
  }
}

function* _getUserListByMerchantId({ payload: merchantId }) {
  // try {
  //   const response = yield call(getUserListByMerchantId, merchantId)
  //   if (response) {
  //     yield put(getUserListByMerchantIdSuccess(response))
  //   }
  // } catch (error) {
  //   yield put(getUserListByMerchantIdFail(error))
  // }
  try {
    const response = yield call(getUserListByMerchantId, merchantId)

    if (response) {
      if (response?.isSuccess)
        yield put(getUserListByMerchantIdSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getUserListByMerchantIdFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getUserListByMerchantIdFail(response.message))
      }
    } else
      yield put(
        getUserListByMerchantIdFail({
          message: merchantId + " Invalid Response.",
        })
      )
  } catch (error) {
    yield put(getUserListByMerchantIdFail(error?.message))
  }
}

function* _isUserExisting({ payload: email }) {
  try {
    const response = yield call(isUserExisting, email)

    if (response) {
      if (response?.isSuccess) yield put(isUserExistingSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              isUserExistingFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }
        yield put(isUserExistingFail(response.message))
      }
    } else
      yield put(isUserExistingFail({ message: user + " Invalid Response." }))
  } catch (error) {
    yield put(isUserExistingFail(error?.message))
  }
}

function* userSaga() {
  yield takeEvery(GET_USER_LIST, _getUserList)
  yield takeEvery(SET_USER, _setUser)
  yield takeEvery(GET_ADMIN_USER_BY_MERCHANTID, _getAdminUserByMerchantId)
  yield takeEvery(DELETE_USER, _deleteUser)
  yield takeEvery(UPDATE_PASSWORD, _updateUserPass)
  yield takeEvery(SET_NEW_USER_PASSWORD, _updateNewUserPass)
  yield takeEvery(UPDATE_USER, _updateUser)
  yield takeEvery(GET_USER, _getUserDetail)
  yield takeEvery(IS_USER_EXISTING, _isUserExisting)
  yield takeEvery(GET_USER_LIST_BY_MERCHANTID, _getUserListByMerchantId)
  yield takeEvery(DEACTIVATE_USER, _deactivateUser)
  yield takeEvery(SET_DUMMY_USER, _setDummyUser)
}

export default userSaga
