export const GET_USER_LIST = "GET_USER_LIST"
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS"
export const GET_USER_LIST_FAIL = "GET_USER_LIST_FAIL"

export const GET_USER_LIST_BY_MERCHANTID = "GET_USER_LIST_BY_MERCHANTID"
export const GET_USER_LIST_BY_MERCHANTID_SUCCESS =
  "GET_USER_LIST_BY_MERCHANTID_SUCCESS"
export const GET_USER_LIST_BY_MERCHANTID_FAIL =
  "GET_USER_LIST_BY_MERCHANTID_FAIL"

export const SET_USER = "SET_USER"
export const SET_USER_SUCCESS = "SET_USER_SUCCESS"
export const SET_USER_FAIL = "SET_USER_FAIL"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

export const DEACTIVATE_USER = "DEACTIVATE_USER"
export const DEACTIVATE_USER_SUCCESS = "DEACTIVATE_USER_SUCCESS"
export const DEACTIVATE_USER_FAIL = "DEACTIVATE_USER_FAIL"

export const SET_NEW_USER_PASSWORD = "SET_NEW_USER_PASSWORD"
export const SET_NEW_USER_PASSWORD_SUCCESS = "SET_NEW_USER_PASSWORD_SUCCESS"
export const SET_NEW_USER_PASSWORD_FAIL = "SET_NEW_USER_PASSWORD_FAIL"

export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"

export const IS_USER_EXISTING = "IS_USER_EXISTING"
export const IS_USER_EXISTING_SUCCESS = "IS_USER_EXISTING_SUCCESS"
export const IS_USER_EXISTING_FAIL = "IS_USER_EXISTING_FAIL"

export const GET_ADMIN_USER_BY_MERCHANTID = "GET_ADMIN_USER_BY_MERCHANTID"
export const GET_ADMIN_USER_BY_MERCHANTID_SUCCESS =
  "GET_ADMIN_USER_BY_MERCHANTID_SUCCESS"
export const GET_ADMIN_USER_BY_MERCHANTID_FAIL =
  "GET_ADMIN_USER_BY_MERCHANTID_FAIL"

export const SET_DUMMY_USER = "SET_DUMMY_USER"
export const SET_DUMMY_USER_SUCCESS = "SET_DUMMY_USER_SUCCESS"
export const SET_DUMMY_USER_FAIL = "SET_DUMMY_USER_FAIL"

export const RETURN_STATE = "RETURN_STATE"
