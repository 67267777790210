import React, { useEffect, useState, useRef } from "react"
import {
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Spinner,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import Header from "./Header"
import PurchaseDetails from "./PurchaseDetails"
import PaymentDetails from "./PaymentDetails"
import PaidInvoice from "./PaidInvoice"
import PaymentMethod from "./PaymentMethod"
import Footer from "./Footer"
import FooterBanner from "./FooterBanner"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown"
import { withTranslation } from "react-i18next"
import {
  getPaymentRequestDetailPublic,
  setPaymentMethod,
  setDeviceFingerPrint,
  setMyfatoorahInitiatePayment,
  setDibsyInitiatePayment,
  setDibsyInitiateSessionApplepay,
} from "store/actions"
import ExpiredInvoice from "./ExpiredInvoice"
import ServicesOffered from "./ServicesOffered"
import MoreInformation from "./MoreInformation"
import SweetAlert from "react-bootstrap-sweetalert"
import { isEmpty, set } from "lodash"
import { paymentGatewayCode } from "../../helpers/commonhelpers/paymentGatewayCode"

export const PaymentMethodContext = React.createContext()
//TODO: rtl

const currentLanguage = localStorage.getItem("I18N_LANGUAGE")

const merchantDeviceFingerPrintInitial = {
  MerchantReference: "",
  DeviceFingerPrint: "",
}

const tokenizationDetailsInitial = {
  signature: "",
  service_command: "",
  access_code: "",
  merchant_identifier: "",
  merchant_reference: "",
  language: "en",
  card_number: "",
  expiry_date: "",
  card_security_code: "",
  card_holder_name: "",
  token_name: "",
  // "remember_me": "YES",
  // "return_url": "https://www.google.com/",
}

const paymentMethodsettings = {
  objectId: "",
  paymentType: "credit",
}

const Invoice = props => {
  const {
    paymentRequestDetail,
    loading,
    redirectUrlResponse,
    applePayEmbeddedMerchantSession,
    error,
    onGetPaymentRequestDetail,
    onSetDeviceFingerPrint,
    onSetMyfatoorahInitiateSessionData,
    onSetDibsyInitiatePayment,
    onSetDibsyInitiateSessionApplepay,
    match: { params },
  } = props

  const myDeviceFingerPrintRef = useRef(null)
  const [isLoaded, setIsLoaded] = useState(loading)
  const [hasSuccess, setHasSuccess] = useState(false)
  const [pr, setPr] = useState()
  const dispatch = useDispatch()
  const [defaultLang, setDefaultLang] = useState(currentLanguage)
  const [errorResponse, setErrorResponse] = useState(undefined)
  const [loadingDeviceFingerPrint, setLoadingDeviceFingerPrint] = useState(true)
  const [merchantDeviceFingerPrintData, setMerchantDeviceFingerPrintData] =
    useState(merchantDeviceFingerPrintInitial)
  const [merchantRef, setMerchantRef] = useState("")
  const [redirectionLink, setRedirectionLink] = useState(undefined)
  const [paymentGatewayDetails, setpaymentGatewayDetails] = useState(undefined)
  const [activePaymentGateways, setActivePaymentGateways] = useState([])
  const [isScriptLoaded, setIsScriptLoaded] = useState(undefined)

  //amazon payment services
  const [apsCreditCustomTokenization, setApsCreditCustomTokenization] =
    useState(tokenizationDetailsInitial)
  const [apsCreditCustom, setApsCreditCustom] = useState(undefined)
  const [apsDebitRedirect, setApsDebitRedirect] = useState(undefined)
  const [apsApplePayRedirect, setApsApplePayRedirect] = useState(undefined)

  //myFatoorah
  const [mfCreditEmbedded, setMfCreditEmbedded] = useState(undefined)
  const [mfDebitRedirect, setMfDebitRedirect] = useState(undefined)
  const [mfApplePayEmbedded, setMfApplePayEmbedded] = useState(undefined)

  //dibsy
  const [dbsyCreditEmbedded, setDbsyCreditEmbedded] = useState(undefined)
  const [dbsyDebitRedirect, setDbsyDebitRedirect] = useState(undefined)
  const [dbsyApplePayEmbedded, setDbsyApplePayEmbedded] = useState(undefined)
  const [dbsyGooglePayEmbedded, setDbsyGooglePayEmbedded] = useState(undefined)
  const [
    dbsyApplePayEmbeddedMerchantSession,
    setDbsyApplePayEmbeddedMerchantSession,
  ] = useState(undefined)

  useEffect(() => {
    onloadPaymentRequestDetails(params.id)
  }, [params, onGetPaymentRequestDetail])

  useEffect(() => {
    if (paymentRequestDetail) {
      setPr(paymentRequestDetail)
      setpaymentGatewayDetails(paymentRequestDetail?.pgDetails)
    }
  }, [paymentRequestDetail])

  //ALL PAYMENTGATEWAYS
  useEffect(() => {
    if (paymentGatewayDetails) {
      paymentGatewayDetails.map(async pg => {
        if (pg) {
          // console.log(pg)
          //TESS
          if (pg?.pgId === paymentGatewayCode.Tess) {
            setLoadingDeviceFingerPrint(false)
          }

          //AMAZON PAYMENT SERVICES
          if (pg?.pgId === paymentGatewayCode.AmazonPaymentServices) {
            await loadDeviceFingerPrint()

            var aps = paymentGatewayDetails?.filter(
              x => x.pgId == paymentGatewayCode.AmazonPaymentServices
            )[0]

            setApsCreditCustom(aps.data.credit_custom)
            setApsDebitRedirect(aps.data.debit_redirect)
            setApsApplePayRedirect(aps.data.applepay_embedded)
            setMerchantRef(aps.data.credit_custom.merchant_reference)
          }

          //MY FATOORAH
          if (pg?.pgId === paymentGatewayCode.MyFatoorah) {
            setLoadingDeviceFingerPrint(false)
            var mf = paymentGatewayDetails?.filter(
              x => x.pgId == paymentGatewayCode.MyFatoorah
            )[0]

            setMfCreditEmbedded(mf.data.credit_embedded)
            setMfDebitRedirect(mf.data.debit_redirect)
            setMfApplePayEmbedded(mf.data.applepay_embedded)
            setMerchantRef("MF")
          }

          //DIBSY
          if (pg?.pgId === paymentGatewayCode.Dibsy) {
            setLoadingDeviceFingerPrint(false)
            var dbsy = paymentGatewayDetails?.filter(
              x => x.pgId == paymentGatewayCode.Dibsy
            )[0]

            setDbsyCreditEmbedded(dbsy.data.credit_embedded)
            setDbsyDebitRedirect(dbsy.data.debit_redirect)
            setDbsyApplePayEmbedded(dbsy.data.applepay_embedded)
            setDbsyGooglePayEmbedded(dbsy.data.googlepay_embedded)
            setMerchantRef("DBSY")
          }
        }
      })
    }
  }, [paymentGatewayDetails])

  //SETTING DEVICE FINGERPRINT for APS

  useEffect(async () => {
    if (isScriptLoaded) {
      await sleep(2000)
      handleChange({
        DeviceFingerPrint: document.getElementById("device_fingerprint")?.value,
      })
    }
  }, [isScriptLoaded])

  useEffect(() => {
    if (merchantDeviceFingerPrintData.DeviceFingerPrint) {
      if (
        !isEmpty(merchantRef) &&
        !isEmpty(merchantDeviceFingerPrintData.DeviceFingerPrint)
      ) {
        setLoadingDeviceFingerPrint(false)
        saveDeviceFingerPrint()
      }
    }
  }, [
    merchantRef,
    merchantDeviceFingerPrintData.DeviceFingerPrint,
    loadingDeviceFingerPrint,
  ])

  //MYFATOORAH & DIBSY REDIRECTION LINK AFTER PAYMENT
  useEffect(() => {
    if (!isEmpty(redirectUrlResponse)) {
      setRedirectionLink(redirectUrlResponse?.url)
    }
  }, [redirectUrlResponse])

  useEffect(() => {
    if (!isEmpty(dbsyApplePayEmbeddedMerchantSession)) {
      setDbsyApplePayEmbeddedMerchantSession(
        dbsyApplePayEmbeddedMerchantSession
      )
    }
  }, [dbsyApplePayEmbeddedMerchantSession])

  useEffect(() => {
    if (redirectionLink && redirectionLink != "") {
      window.location.replace(redirectionLink)
    }
  }, [redirectionLink])

  // ERROR RESPONSES

  useEffect(() => {
    if (error !== undefined && !isEmpty(error)) {
      setErrorResponse(error)
    }
  }, [error])

  useEffect(() => {}, [errorResponse])

  //LANGUAGE
  useEffect(() => {
    if (
      pr?.paymentRequestDetails?.paymentRequestSettings?.language !== undefined
    ) {
      const configuredLang =
        pr?.paymentRequestDetails?.paymentRequestSettings?.language.toLowerCase()

      if (currentLanguage !== configuredLang) setDefaultLang(configuredLang)
    }
  }, [pr])

  useEffect(() => {
    if (defaultLang && defaultLang !== undefined) {
      let lang = localStorage.getItem("i18nextLng")

      if (lang != defaultLang) {
        if ("caches" in window) {
          caches.keys().then(names => {
            // Delete all the cache files
            names.forEach(name => {
              caches.delete(name)
            })
          })

          // Makes sure the page reloads. Changes are only visible after you refresh.
          window.location.reload(true)
        }
        localStorage.clear()
        localStorage.setItem("i18nextLng", defaultLang)
        localStorage.setItem("I18N_LANGUAGE", defaultLang)
      }
    }
  }, [defaultLang])

  useEffect(() => {
    setIsLoaded(loading)
    checkForAnySuccessfulTransactions()
  }, [loading])

  ////FUNCTIONS

  function handleChange(changes) {
    handleDeviceFingerPrintChange({
      ...merchantDeviceFingerPrintData,
      ...changes,
    })
  }

  function handleDeviceFingerPrintChange(changes) {
    setMerchantDeviceFingerPrintData(changes)
  }

  function sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }

  const saveDeviceFingerPrint = () => {
    var merchantReference = merchantRef
    var deviceFingerPrint = merchantDeviceFingerPrintData.DeviceFingerPrint

    const data = {
      merchantReference,
      deviceFingerPrint,
    }

    onSetDeviceFingerPrint(data)
    setActivePaymentGateways([])
  }

  const onloadPaymentRequestDetails = () => {
    if (params && typeof params.id === "string") {
      onGetPaymentRequestDetail(params.id)
    }
  }

  const loadDeviceFingerPrint = () => {
    let input = document.createElement("input")
    input.type = "hidden"
    input.id = "device_fingerprint"
    input.name = "device_fingerprint"
    input.value = ""

    document.body.appendChild(input)

    let snareScript = document.createElement("script")
    snareScript.src = "https://mpsnare.iesnare.com/snare.js"
    snareScript.async = true
    document.body.appendChild(snareScript)

    let variables = document.createElement("script")
    variables.innerHTML =
      'var io_bbout_element_id = "device_fingerprint"; var io_install_stm = false; var io_exclude_stm = 0; var io_install_flash = false; var io_enable_rip = true;'
    variables.async = true

    document.body.appendChild(variables)

    setIsScriptLoaded(true)
    return Promise.resolve(true)
  }

  function handleTokenizationDetailsChange(changes) {
    setApsCreditCustomTokenization(changes)
  }

  function handleCreditCard() {
    const data = {
      objectId: paymentRequestDetail.paymentRequestDetails?._id,
      paymentType: "credit",
    }
    if (data.objectId) dispatch(setPaymentMethod(data))
  }

  function handleDebitCard() {
    const data = {
      objectId: paymentRequestDetail?.paymentRequestDetails?._id,
      paymentType: "debit",
    }

    if (data.objectId) dispatch(setPaymentMethod(data))
  }

  function handleOoredoo() {
    const data = {
      objectId: paymentRequestDetail?.paymentRequestDetails?._id,
      paymentType: "omm",
    }
    dispatch(setPaymentMethod(data))
  }

  function checkForAnySuccessfulTransactions() {
    const hasSuccessStatus = paymentRequestDetail?.paymentHistoryDetails?.some(
      x => x.isSuccessful === true
    )
    if (hasSuccessStatus) {
      setHasSuccess(hasSuccessStatus)
    }
  }

  function renderMethodContent() {
    if (
      paymentRequestDetail?.paymentRequestDetails?.generatedLinkStatus !=
      "Expired"
    ) {
      return (
        <>
          <Row>
            <PaymentDetails
              amount={paymentRequestDetail?.paymentRequestDetails?.amount}
              currency={paymentRequestDetail?.paymentRequestDetails?.currency}
              termsConditionUrl={
                paymentRequestDetail?.merchantDetails?.termsConditionUrl
              }
            />
          </Row>

          {loadingDeviceFingerPrint == false && (
            <>
              <PaymentMethodContext.Provider value={PaymentMethodContextValue}>
                <PaymentMethod
                  paymentRequestDetails={
                    paymentRequestDetail?.paymentRequestDetails
                  }
                  apsCreditCustomTokenization={apsCreditCustomTokenization}
                  apsCreditCustom={apsCreditCustom}
                  apsDebitRedirect={apsDebitRedirect}
                  apsApplePayRedirect={apsApplePayRedirect}
                  mfCreditEmbedded={mfCreditEmbedded}
                  mfDebitRedirect={mfDebitRedirect}
                  mfApplePayEmbedded={mfApplePayEmbedded}
                  dbsyCreditEmbedded={dbsyCreditEmbedded}
                  dbsyDebitRedirect={dbsyDebitRedirect}
                  dbsyApplePayEmbedded={dbsyApplePayEmbedded}
                  dbsyGooglePayEmbedded={dbsyGooglePayEmbedded}
                  dbsyApplePayEmbeddedMerchantSession={
                    dbsyApplePayEmbeddedMerchantSession
                  }
                />
              </PaymentMethodContext.Provider>
            </>
          )}

          {loadingDeviceFingerPrint && <LoadingSpinnerThreeDots />}
        </>
      )
    } else {
      return (
        <>
          <Row className="mb-2 mt-2">
            <ExpiredInvoice
              amount={paymentRequestDetail?.paymentRequestDetails?.amount}
              currency={paymentRequestDetail?.paymentRequestDetails?.currency}
            />
          </Row>
        </>
      )
    }
  }

  function addActivePaymentGateway(value) {
    var _activePaymentGateways = activePaymentGateways

    if (!_activePaymentGateways.includes(value)) {
      _activePaymentGateways.push(value)
    }

    setActivePaymentGateways(_activePaymentGateways)
  }

  ///context
  const PaymentMethodContextValue = {
    handleCreditCard,
    handleDebitCard,
    handleOoredoo,
    handleTokenizationDetailsChange,
    saveDeviceFingerPrint,
    onSetMyfatoorahInitiateSessionData,
    onSetDibsyInitiatePayment,
    onSetDibsyInitiateSessionApplepay,
  }
  return (
    <React.Fragment>
      {isLoaded && <LoadingSpinnerThreeDots />}
      {isLoaded === false && (
        <>
          <div
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              zIndex: "100",
            }}
          >
            <LanguageDropdown />
          </div>

          {/* ERROR RESPONSES */}

          {errorCatching(errorResponse, setErrorResponse)}

          <div>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <div>
                <Container fluid>
                  <Card>
                    <CardBody>
                      <div className="justify-content-center container-alignment">
                        <Row>
                          <Header
                            paymentRequestDetail={
                              paymentRequestDetail?.paymentRequestDetails
                            }
                            merchantDetail={
                              paymentRequestDetail?.merchantDetails
                            }
                          />
                        </Row>

                        <hr></hr>
                        {paymentRequestDetail?.paymentRequestDetails
                          ?.paymentStatus === "Success" || hasSuccess ? (
                          <Row className="mb-1 mt-1">
                            <PaidInvoice
                              paidDetails={
                                paymentRequestDetail?.paymentRequestDetails
                                  ?.paidDetails
                              }
                              amount={
                                paymentRequestDetail?.paymentRequestDetails
                                  ?.amount
                              }
                              currency={
                                paymentRequestDetail?.paymentRequestDetails
                                  ?.currency
                              }
                            />
                          </Row>
                        ) : (
                          renderMethodContent()
                        )}
                      </div>
                      <div>
                        <hr></hr>
                        {paymentRequestDetail?.paymentRequestDetails
                          ?.isExpress === false && (
                          <Row>
                            <PurchaseDetails
                              email={
                                paymentRequestDetail?.paymentRequestDetails
                                  ?.customerDetails?.email
                              }
                              source={
                                paymentRequestDetail?.paymentRequestDetails
                                  ?.itemSource
                              }
                              purchasedItems={
                                paymentRequestDetail?.purchasedItems
                              }
                            />
                          </Row>
                        )}
                        <Row>
                          <MoreInformation
                            servicesOffered={
                              paymentRequestDetail?.merchantDetails?.services
                            }
                            remarks={
                              paymentRequestDetail?.paymentRequestDetails
                                ?.remarks
                            }
                            user={paymentRequestDetail?.userDetails}
                            merchant={paymentRequestDetail?.merchantDetails}
                          />
                        </Row>
                        <Row>
                          <Footer
                            user={paymentRequestDetail?.userDetails}
                            merchant={paymentRequestDetail?.merchantDetails}
                          />
                        </Row>
                        <Row>
                          <FooterBanner
                            banner={
                              paymentRequestDetail?.merchantDetails?.banner
                            }
                          />
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Container>
              </div>
            </Col>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

Invoice.propTypes = {
  paymentRequestDetail: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  onGetPaymentRequestDetail: PropTypes.func,
  error: PropTypes.object,
  mfInitiatedSessionRedirectUrlResponse: PropTypes.object,
  mfExecutePaymentErrorResponse: PropTypes.object,
}

const mapStateToProps = pr => ({
  paymentRequestDetail: pr.paymentRequests.paymentRequestDetail,
  loading: pr.paymentRequests.loading,
  error: pr.paymentRequests.error,
  //myfatoorah & dbsy
  redirectUrlResponse: pr.paymentRequests.redirectUrlResponse,
  applePayEmbeddedMerchantSession:
    pr.paymentRequests.applePayEmbeddedMerchantSession,
})

const mapDispatchToProps = dispatch => ({
  onGetPaymentRequestDetail: id => dispatch(getPaymentRequestDetailPublic(id)),
  onSetDeviceFingerPrint: data => dispatch(setDeviceFingerPrint(data)),
  onSetMyfatoorahInitiateSessionData: data =>
    dispatch(setMyfatoorahInitiatePayment(data)),
  onSetDibsyInitiatePayment: data => dispatch(setDibsyInitiatePayment(data)),
  onSetDibsyInitiateSessionApplepay: data =>
    dispatch(setDibsyInitiateSessionApplepay(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Invoice)))
