import { load } from "dotenv"
import {
  GET_MERCHANT_DETAIL,
  GET_MERCHANT_DETAIL_FAIL,
  GET_MERCHANT_DETAIL_SUCCESS,
  GET_MERCHANT_LIST,
  GET_MERCHANT_LIST_FAIL,
  GET_MERCHANT_LIST_SUCCESS,
  GET_MERCHANT_LIST_REPORT,
  GET_MERCHANT_LIST_REPORT_SUCCESS,
  GET_MERCHANT_LIST_REPORT_FAIL,
  GET_MERCHANT_IDS,
  GET_MERCHANT_IDS_SUCCESS,
  GET_MERCHANT_IDS_FAIL,
  SET_MERCHANT,
  SET_MERCHANT_SUCCESS,
  SET_MERCHANT_FAIL,
  REGISTER_MERCHANT,
  REGISTER_MERCHANT_SUCCESS,
  REGISTER_MERCHANT_FAIL,
  GET_MERCHANT_DETAIL_EXTERNAL,
  GET_MERCHANT_DETAIL_EXTERNAL_SUCCESS,
  GET_MERCHANT_DETAIL_EXTERNAL_FAIL,
  GET_MERCHANT_BY_OID,
  GET_MERCHANT_BY_OID_SUCCESS,
  GET_MERCHANT_BY_OID_FAIL,
  UPDATE_MERCHANT,
  UPDATE_MERCHANT_SUCCESS,
  UPDATE_MERCHANT_FAIL,
  RETURN_STATE,
} from "./actionTypes"

const INIT_STATE = {
  merchantList: [],
  merchantListReport: [],
  merchantUpdated: undefined,
  merchantCreated: undefined,
  merchantDetail: {},
  error: {},
  loading: false,
  email: false,
}

const merchants = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MERCHANT_DETAIL:
      return {
        ...state,
        merchantDetail: action.payload,
        loading: true,
      }

    case GET_MERCHANT_DETAIL_SUCCESS:
      return {
        ...state,
        merchantDetail: action.payload,
        loading: false,
      }

    case GET_MERCHANT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MERCHANT_LIST:
      return (state = {
        ...state,
        merchantList: action.payload,
        loading: true,
      })

    case GET_MERCHANT_LIST_SUCCESS:
      return {
        ...state,
        merchantList: action.payload,
        loading: false,
      }

    case GET_MERCHANT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: true,
      }

    case GET_MERCHANT_LIST_REPORT:
      return (state = {
        ...state,
        merchantListReport: action.payload,
      })

    case GET_MERCHANT_LIST_REPORT_SUCCESS:
      return {
        ...state,
        merchantListReport: action.payload,
      }

    case GET_MERCHANT_LIST_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MERCHANT_IDS:
      return (state = {
        ...state,
        loading: true,
      })
    case GET_MERCHANT_IDS_SUCCESS:
      return {
        ...state,
        merchantIds: action.payload,
        loading: false,
      }
    case GET_MERCHANT_IDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case SET_MERCHANT:
      return {
        ...state,
        merchantDetail: action.payload,
        loading: true,
        email: true,
      }
    case SET_MERCHANT_SUCCESS:
      return {
        ...state,
        merchantDetail: action.payload,
        loading: false,
        email: false,
        merchantCreated: true,
      }

    case SET_MERCHANT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        email: false,
        merchantCreated: false,
      }
    case REGISTER_MERCHANT:
      return {
        ...state,
        merchantDetail: action.payload,
        loading: true,
        email: true,
      }
    case REGISTER_MERCHANT_SUCCESS:
      return {
        ...state,
        merchantDetail: action.payload,
        registerMerchant: true,
        loading: false,
        email: false,
        merchantCreated: true,
      }

    case REGISTER_MERCHANT_FAIL:
      return {
        ...state,
        error: action.payload,
        registerMerchant: false,
        merchantCreated: false,
        loading: false,
        email: false,
      }
    case GET_MERCHANT_DETAIL_EXTERNAL:
      return {
        ...state,
        merchantDetail: action.payload,
        loading: true,
      }

    case GET_MERCHANT_DETAIL_EXTERNAL_SUCCESS:
      return {
        ...state,
        merchantDetail: action.payload,
        loading: false,
      }

    case GET_MERCHANT_DETAIL_EXTERNAL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_MERCHANT_BY_OID:
      return {
        ...state,
        merchantDetail: action.payload,
        loading: true,
      }
    case GET_MERCHANT_BY_OID_SUCCESS:
      return {
        ...state,
        merchantDetail: action.payload,
        loading: false,
      }
    case GET_MERCHANT_BY_OID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_MERCHANT:
      return {
        ...state,
        merchantDetail: action.payload,
        loading: true,
      }
    case UPDATE_MERCHANT_SUCCESS:
      return {
        ...state,
        merchantDetail: action.payload,
        merchantUpdated: true,
        loading: false,
      }
    case UPDATE_MERCHANT_FAIL:
      return {
        ...state,
        error: action.payload,
        merchantUpdated: false,
        loading: false,
      }
    case RETURN_STATE:
      return {
        ...state,
        merchantList: [],
        merchantDetail: {},
        merchantUpdated: undefined,
        error: undefined,
        registerMerchant: undefined,
        loading: undefined,
        email: undefined,
        merchantCreated: undefined,
      }

    default:
      return state
  }
}

export default merchants
